exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-index-js": () => import("./../../../src/pages/aktuelles/index.js" /* webpackChunkName: "component---src-pages-aktuelles-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forderung-js": () => import("./../../../src/pages/forderung.js" /* webpackChunkName: "component---src-pages-forderung-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offene-finanzen-js": () => import("./../../../src/pages/offene-finanzen.js" /* webpackChunkName: "component---src-pages-offene-finanzen-js" */),
  "component---src-pages-offener-brief-an-stadtraedte-js": () => import("./../../../src/pages/offener-brief-an-stadtraedte.js" /* webpackChunkName: "component---src-pages-offener-brief-an-stadtraedte-js" */),
  "component---src-pages-spenden-js": () => import("./../../../src/pages/spenden.js" /* webpackChunkName: "component---src-pages-spenden-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-unterschreiben-js": () => import("./../../../src/pages/unterschreiben.js" /* webpackChunkName: "component---src-pages-unterschreiben-js" */),
  "component---src-pages-ziel-js": () => import("./../../../src/pages/ziel.js" /* webpackChunkName: "component---src-pages-ziel-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

